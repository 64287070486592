import React, {
  Suspense,
  lazy,
  useRef,
  useState,
  useContext,
  useEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import StepOne from "./Order/Orderform/StepOne";
import StepTwo from "./Order/Orderform/StepTwo";
import StepThree from "./Order/Orderform/StepThree";
import StepFour from "./Order/Orderform/StepFour";
import StepFive from "./Order/Orderform/StepFive";
import StepSix from "./Order/Orderform/StepSix";
import StepSeven from "./Order/Orderform/StepSeven";
import StepEight from "./Order/Orderform/StepEight";
import OrderSuccess from "./Order/Orderform/OrderSuccess";
import {
  updateSession,
  loaderBar,
  apibaseURl,
  currencySymbol,
  errorToast,
  successToast,
  loggedInCustomerDetails,
  getvalidationErr,
} from "../functions/Function";
const Home = (props) => {
  const [reorder, setReorder] = useState(false);
  const [step, setStep] = useState(1);
  const [stepvalidation, setstepvalidation] = useState(true);

  const nextStep = (stepCount) => {
    setStep(stepCount);
    setstepvalidation(true);
  };

  const backStep = (stepCount) => {
    setStep(stepCount);
    setstepvalidation(false);
  };

  const [inputs, setInputs] = useState({
    user_id: loggedInCustomerDetails("id"),
    order_origin: "",
    reorder: "No",
    delivery_type: "asready", // asap
    company_name: "",
    buyer_email: "",
    buyer_name: "",
    buyer_phone: "",
    delivery_date: "",
    terms: "",
    usps_shipping_address: "",
    fedex_shipping_address: "",
    finish: "Matte",
    hangtag: "Yes",
    madeinusa: "Yes",
    barcode: "Yes",
    prepriced: "No",
    price: 0.0,
    other: "",
    displaytype: "display_by_keyword",

    // display_type_metal: "four_side_metal_floor",
    // display_type_metal_qty: 1,
    // display_type_metal_price: 8450.0,

    display_types: [
      {
        id: 1,
        display_type_metal: "four_side_metal_counter",
        label: "4 Sided Metal Counter",
        qty: 0,
        display_type_metal_price: 2460.0,
        price_label: "2,460",
      },
      {
        id: 2,
        display_type_metal: "four_side_metal_floor",
        label: "4 Sided Metal Floor",
        qty: 0,
        display_type_metal_price: 8450.0,
        price_label: "8,450",
      },
      {
        id: 3,
        display_type_metal: "four_side_wood_counter",
        label: "4 Sided Wood Counter",
        qty: 0,
        display_type_metal_price: 2675.0,
        price_label: "2,675",
      },
      // {
      //   display_type_metal: "two_side_wood_counter",
      //   label: "2 Sided Wood Floor",
      //   qty: 0,
      //   display_type_metal_price: 3450.0,
      //   price_label: "3,450",
      // },
      {
        id: 4,
        display_type_metal: "four_side_wood_floor",
        label: "4 Sided Wood Floor",
        qty: 0,
        display_type_metal_price: 6900.0,
        price_label: "6,900",
      },

      // {
      //   display_type_metal: "two_side_wood_floor",
      //   label: "4 Sided Wood Floor 2 Floor",
      //   qty: 0,
      //   display_type_metal_price: 10900.0,
      //   price_label: "10,900",
      // },
    ],
    category: [
      { keyword: "", percentage: 0 },
      { keyword: "", percentage: 0 },
      { keyword: "", percentage: 0 },
      { keyword: "", percentage: 0 },
      { keyword: "", percentage: 0 },
      { keyword: "", percentage: 0 },
      { keyword: "", percentage: 0 },
      { keyword: "", percentage: 0 },
      { keyword: "", percentage: 0 },
      { keyword: "", percentage: 0 },
    ],
    display: [
      {
        size: "Small",
        qty: "0",
        design_number: "",
        customization: "",
        price: 0,
      },
      {
        size: "Small",
        qty: "0",
        design_number: "",
        customization: "",
        price: 0,
      },
      {
        size: "Small",
        qty: "0",
        design_number: "",
        customization: "",
        price: 0,
      },
      {
        size: "Small",
        qty: "0",
        design_number: "",
        customization: "",
        price: 0,
      },
      {
        size: "Small",
        qty: "0",
        design_number: "",
        customization: "",
        price: 0,
      },
    ],
    note: "",
    discount: 0,
    discount_percentage: 0,
    additional_note: "",
    terms_and_conditions: "accept",
    total: 0.0,
    signature: "",
    files: [],
  });

  const [validation, setvalidation] = useState({
    buyer_name: "",
    company_name: "",
    buyer_email: "",
    buyer_phone: "",
    delivery_date: "",
    terms: "",
    usps_shipping_address: "",
    fedex_shipping_address: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    validateInputs(name, value);
  };

  const validateInputs = (name, value) => {
    if (name == "order_origin" && value == "") {
      setvalidation((values) => ({
        ...values,
        order_origin: "Please enter order origin.",
      }));
    }

    if (name == "order_origin" && value != "") {
      setvalidation((values) => ({ ...values, order_origin: "" }));
    }

    if (name == "buyer_name" && value == "") {
      setvalidation((values) => ({
        ...values,
        buyer_name: "Please enter buyer name.",
      }));
    }

    if (name == "buyer_name" && value != "") {
      setvalidation((values) => ({ ...values, buyer_name: "" }));
    }

    if (name == "company_name" && value == "") {
      setvalidation((values) => ({
        ...values,
        company_name: "Please enter company name.",
      }));
    }
    if (name == "company_name" && value != "") {
      setvalidation((values) => ({ ...values, company_name: "" }));
    }

    if (name == "buyer_email" && value == "") {
      setvalidation((values) => ({
        ...values,
        buyer_email: "Please enter buyer email.",
      }));
    }
    if (name == "buyer_email" && value != "") {
      setvalidation((values) => ({ ...values, buyer_email: "" }));
    }
    var phoneno = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    if (name == "buyer_phone" && value == "") {
      setvalidation((values) => ({
        ...values,
        buyer_phone: "Please enter buyer Phone.",
      }));
    }

    if (name == "buyer_phone" && value != "") {
      if (value.match(phoneno)) {
        setvalidation((values) => ({ ...values, buyer_phone: "" }));
      } else {
        setvalidation((values) => ({
          ...values,
          buyer_phone: "Not a valid Phone Number.",
        }));
      }
    }

    if (name == "terms" && value == "") {
      setvalidation((values) => ({ ...values, terms: "Please enter terms." }));
    }
    if (name == "terms" && value != "") {
      setvalidation((values) => ({ ...values, terms: "" }));
    }

    if (name == "usps_shipping_address" && value == "") {
      setvalidation((values) => ({
        ...values,
        usps_shipping_address: "Please enter usps shipping address.",
      }));
    }
    if (name == "usps_shipping_address" && value != "") {
      setvalidation((values) => ({ ...values, usps_shipping_address: "" }));
    }
    if (name == "fedex_shipping_address" && value == "") {
      setvalidation((values) => ({
        ...values,
        fedex_shipping_address: "Please enter fedex shipping address.",
      }));
    }
    if (name == "fedex_shipping_address" && value != "") {
      setvalidation((values) => ({ ...values, fedex_shipping_address: "" }));
    }
  };

  const validtionError = () => {
    if (inputs.order_origin == "") {
      setvalidation((values) => ({
        ...values,
        order_origin: "Please enter order origin.",
      }));
    }

    if (inputs.buyer_name == "") {
      setvalidation((values) => ({
        ...values,
        buyer_name: "Please enter buyer name.",
      }));
    }

    if (inputs.buyer_name == "") {
      setvalidation((values) => ({
        ...values,
        buyer_name: "Please enter buyer name.",
      }));
    }

    if (inputs.company_name == "") {
      setvalidation((values) => ({
        ...values,
        company_name: "Please enter company name.",
      }));
    }
    if (inputs.buyer_email == "") {
      setvalidation((values) => ({
        ...values,
        buyer_email: "Please enter buyer email.",
      }));
    }
    if (inputs.buyer_phone == "") {
      setvalidation((values) => ({
        ...values,
        buyer_phone: "Please enter buyer phone.",
      }));
    }
    var phoneno = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    if (inputs.buyer_phone.match(phoneno)) {
      setvalidation((values) => ({ ...values, buyer_phone: "" }));
    } else {
      setvalidation((values) => ({
        ...values,
        buyer_phone: "Not a valid Phone Number.",
      }));
    }

    // if (inputs.delivery_date == "") {
    //   setvalidation((values) => ({
    //     ...values,
    //     delivery_date: "Please enter delivery date.",
    //   }));
    // }
    if (inputs.terms == "") {
      setvalidation((values) => ({ ...values, terms: "Please enter terms." }));
    }
    if (inputs.usps_shipping_address == "") {
      setvalidation((values) => ({
        ...values,
        usps_shipping_address: "Please enter usps shipping address.",
      }));
    }
    if (inputs.fedex_shipping_address == "") {
      setvalidation((values) => ({
        ...values,
        fedex_shipping_address: "Please enter fedex shipping address.",
      }));
    }
  };

  const validationCheckStepOne = () => {
    if (inputs.reorder == "No") {
      if (
        inputs.order_origin != "" &&
        inputs.buyer_name != "" &&
        inputs.company_name != "" &&
        inputs.buyer_email != "" &&
        inputs.buyer_phone != "" &&
        // inputs.delivery_date != "" &&
        inputs.terms != "" &&
        inputs.usps_shipping_address != "" &&
        inputs.fedex_shipping_address != ""
      ) {
        setstepvalidation(false);
        setStep(2);
      } else {
        setstepvalidation(true);
      }
    }

    if (inputs.reorder == "Yes") {
      if (inputs.buyer_email != "") {
        setstepvalidation(false);
        setStep(2);
      }
    }
  };

  const validationCheckStepTwo = () => {
    setstepvalidation(false);
    setStep(3);
  };
  const validationCheckStepThree = (displaytypes) => {
    if (
      displaytypes == "display_plus_design" ||
      displaytypes == "display_by_keyword"
    ) {
      setstepvalidation(false);
      setStep(4);
    }
    if (displaytypes == "design_only") {
      setstepvalidation(false);
      setStep(9);
    }
  };

  const validationCheckStepfour = () => {
    if (inputs.displaytype == "display_plus_design") {
      setstepvalidation(false);
      setStep(9);
    }
    if (inputs.displaytype == "display_by_keyword") {
      setstepvalidation(false);
      setStep(5);
    }
  };

  const validationCheckStepFive = () => {
    setstepvalidation(false);
    setStep(6);
  };

  const validationCheckStepSix = () => {
    setstepvalidation(false);
    setStep(7);
  };

  const validationCheckStepSeven = () => {
    setstepvalidation(false);
    setStep(8);
  };

  const validationCheckStepEight = () => {
    setstepvalidation(false);
    setStep(6);
  };

  useEffect(() => {
    if (props.updateorderId > 0) {
      setInputs(props.updateinputs);
    }
  }, [props.updateinputs]);

  return (
    <>
      {/* {console.log(inputs)}
      {console.log("Update Order ID" + props.updateorderId)} */}
      {step == 1 && (
        <StepOne
          inputs={inputs}
          setInputs={setInputs}
          validation={validation}
          setvalidation={setvalidation}
          handleChange={handleChange}
          validateInputs={validateInputs}
          validtionError={validtionError}
          step={step}
          stepvalidation={stepvalidation}
          nextStep={nextStep}
          backStep={backStep}
          validationCheckStepOne={validationCheckStepOne}
          updateInputState={props.updateInputState}
          setReorder={props.setReorder}
        />
      )}
      {step == 2 && (
        <StepTwo
          inputs={inputs}
          setInputs={setInputs}
          validation={validation}
          setvalidation={setvalidation}
          handleChange={handleChange}
          validateInputs={validateInputs}
          validtionError={validtionError}
          step={step}
          stepvalidation={stepvalidation}
          nextStep={nextStep}
          backStep={backStep}
          validationCheckStepTwo={validationCheckStepTwo}
        />
      )}

      {step == 3 && (
        <StepThree
          inputs={inputs}
          setInputs={setInputs}
          validation={validation}
          setvalidation={setvalidation}
          handleChange={handleChange}
          validateInputs={validateInputs}
          validtionError={validtionError}
          step={step}
          stepvalidation={stepvalidation}
          nextStep={nextStep}
          backStep={backStep}
          validationCheckStepThree={validationCheckStepThree}
        />
      )}

      {step == 4 && (
        <StepFour
          inputs={inputs}
          setInputs={setInputs}
          validation={validation}
          setvalidation={setvalidation}
          handleChange={handleChange}
          validateInputs={validateInputs}
          validtionError={validtionError}
          step={step}
          stepvalidation={stepvalidation}
          nextStep={nextStep}
          backStep={backStep}
          validationCheckStepfour={validationCheckStepfour}
        />
      )}

      {step == 5 && (
        <StepFive
          inputs={inputs}
          setInputs={setInputs}
          validation={validation}
          setvalidation={setvalidation}
          handleChange={handleChange}
          validateInputs={validateInputs}
          validtionError={validtionError}
          step={step}
          stepvalidation={stepvalidation}
          nextStep={nextStep}
          backStep={backStep}
          validationCheckStepFive={validationCheckStepFive}
        />
      )}

      {step == 6 && (
        <StepSix
          inputs={inputs}
          setInputs={setInputs}
          validation={validation}
          setvalidation={setvalidation}
          handleChange={handleChange}
          validateInputs={validateInputs}
          validtionError={validtionError}
          step={step}
          stepvalidation={stepvalidation}
          nextStep={nextStep}
          backStep={backStep}
          validationCheckStepSix={validationCheckStepSix}
        />
      )}

      {step == 7 && (
        <StepSeven
          inputs={inputs}
          setInputs={setInputs}
          validation={validation}
          setvalidation={setvalidation}
          handleChange={handleChange}
          validateInputs={validateInputs}
          validtionError={validtionError}
          step={step}
          stepvalidation={stepvalidation}
          nextStep={nextStep}
          backStep={backStep}
          validationCheckStepSeven={validationCheckStepSeven}
          updateorderId={props.updateorderId}
          setupdateorderId={props.setupdateorderId}
        />
      )}

      {step == 8 && (
        <OrderSuccess
          inputs={inputs}
          setInputs={setInputs}
          validation={validation}
          setvalidation={setvalidation}
          handleChange={handleChange}
          validateInputs={validateInputs}
          validtionError={validtionError}
          step={step}
          stepvalidation={stepvalidation}
          nextStep={nextStep}
          backStep={backStep}
          validationCheckStepSeven={validationCheckStepSeven}
        />
      )}

      {step == 9 && (
        <StepEight
          inputs={inputs}
          setInputs={setInputs}
          validation={validation}
          setvalidation={setvalidation}
          handleChange={handleChange}
          validateInputs={validateInputs}
          validtionError={validtionError}
          step={step}
          stepvalidation={stepvalidation}
          nextStep={nextStep}
          backStep={backStep}
          validationCheckStepEight={validationCheckStepEight}
        />
      )}
    </>
  );
};

export default Home;
