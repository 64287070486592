
import React , { useState , useEffect } from "react";
import { Link , NavLink  } from "react-router-dom";
import Designitem from '../../../components/web/Designitem';
import {errorToast} from '../../../functions/Function';
import { ToastContainer, toast, Zoom } from 'react-toastify';


const  StepEight = ({inputs , setInputs , validation , setvalidation , handleChange , validateInputs , validtionError , step , stepvalidation , nextStep , backStep ,  validationCheckStepEight}) => {
  const [percentage, setpercentage] = useState(0);
  const [validationAlert, setvalidationAlert] = useState('');

  const checkkeyValidation = () =>
  {      
        let size = true;
        let qty = true;
        let design_number = true;
        let customization = true;
      //   {inputs.display.map(function(object, i){
      //       if(object.size == '')
      //          {
      //             size = false;
      //          }
      //          if(object.qty == '')
      //          {
      //             qty = false;
      //          }
      //          if(object.design_number == '')
      //          {
      //             design_number = false;
      //          }
      //          if(object.customization == '')
      //          {
      //             customization = false;
      //          }
      //    })}

        
         // if(size == false)
         // {
         //    errorToast('Fill size fields for all rows' , 'top-right' , '8000' )
         //    return
         // }
         // if(qty == false)
         // {
         //    errorToast('Fill qty fields for all rows' , 'top-right' , '8000' )
         //    return
         // }
         // if(design_number == false)
         // {
         //    errorToast('Fill design number fields for all rows' , 'top-right' , '8000' )
         //    return
         // }
         // if(customization == false)
         // {
         //    errorToast('Fill customization fields for all rows' , 'top-right' , '8000' )
         //    return
         // }
         

         // if(size == true && qty == true && design_number == true && customization == true)
         // {
         //    validationCheckStepEight();
         // }
         validationCheckStepEight();
        
         
  }

  const custombackstep = () =>
   {
      if(inputs.displaytype == 'design_only')
      {
         backStep(3)
      }
      if(inputs.displaytype == 'display_plus_design')
      {
         backStep(4)
      }
     
   }

   const addmore = () =>
   {
      for (let i = 0; i < 5; i++) {
         let row = {size:'Small' , qty:0 , design_number:"" , customization : "" , price:0};
         let  dispplaynew = inputs.display;
         dispplaynew[dispplaynew.length]= row;
          setInputs(values => ({ ...values, display: dispplaynew }))
       }
   }

  useEffect(() => {
         window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
  return (
    <>
      <ToastContainer />
      <section className="section order-detail-2">
            <div className="container">
               <div className="form-box row my-keryword-form-list">
                  <div className="col-lg-12  inner-display-box-checks">
                     <div className="row">
                        <div className="col-lg-12 col-sm-12 ">
                           <div className="input-digits-box mt-4 overflow">
                             
                                 <div className="row g-3 align-items-center mb-3">
                                    <div className="col-lg-12 mobile-none">
                                       <div className="row">
                                       <div className="col-1">
                                             <div className="input-digits-heading">
                                                S.NO
                                             </div>
                                          </div>

                                          <div className="col-6 col-sm-3 col-md-2 col-lg-2">
                                             <div className="input-digits-heading">
                                                Size
                                             </div>
                                          </div>
                                          <div className="col-6 col-sm-2 col-md-2 col-lg-2">
                                             <div className="input-digits-heading">
                                                Quantity
                                             </div>
                                          </div>
                                          <div className="col-6 col-sm-2 col-md-3 col-lg-2">
                                             <div className="input-digits-heading">
                                                Design Number
                                             </div>
                                          </div>
                                          <div className="col-6 col-sm-5 col-md-5 col-lg-5">
                                             <div className="input-digits-heading">
                                                Customization
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    {inputs.display.map(function(data, i){
                                    return (
                                             <Designitem 
                                             key={i} id={`design_${i}`} 
                                                data={data}  
                                                index={i} 
                                                inputs={inputs} 
                                                setInputs={setInputs} 
                                             />
                                             )
                                       })}
                                   
                                    </div>
                                    <div className="addmorebtn">
                                       <button className="btn btn-sm btn-success"
                                          onClick={()=>addmore()}
                                       >Add More <i
                                          className="fa-solid fa-plus"></i> </button>
                                    </div>
                                 <div className="text-center">
                                
                              <button onClick={()=> custombackstep() }  className="btn site-btn backbtn"> <span><i
                              className="fa-solid fa-chevron-left" disabled></i></span> Back</button>

                              <button onClick={()=> checkkeyValidation()}   className="btn site-btn">Next <span><i
                              className="fa-solid fa-chevron-right" disabled></i></span></button>
                                 </div>
                              
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
    </>

  );
}

export default StepEight;
